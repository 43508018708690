<template>
  <div>
    <div class="esims-main-container">
      <section-hero-base v-if="!isCoBranded">
        <template #hero-title>
          <hero
            :title="$t('scenes.homepage.head-title')"
            :description="
              $t('scenes.homepage.head-description', {
                count: $t('scenes.homepage.head-description-count'),
              })
            "
            :seo-title="generatedSeoTitle"
            class="tw-relative"
          />
        </template>

        <template #country-search>
          <country-search
            ref="countrySearch"
            :list-type="listType"
            @countrySearchClearAfter="onCountrySearchClearAfter"
          />
        </template>

        <template #tabs>
          <store-tabs :tabs="tabs" :prop-list-type="listType" />
        </template>
      </section-hero-base>

      <section-hero-cobranded v-else>
        <template #hero-title>
          <cobranding-hero-title :title="coBrandedHeroTitle" :seo-title="generatedSeoTitle" class="tw-relative" />
        </template>

        <template #country-search>
          <country-search
            ref="countrySearch"
            :list-type="listType"
            @countrySearchClearAfter="onCountrySearchClearAfter"
            class="tw-w-full tw-ml-0 sm:tw-w-[500px] sm:!tw-mb-16"
          />
        </template>

        <template #tabs>
          <store-tabs :tabs="tabs" :prop-list-type="listType" />
        </template>
      </section-hero-cobranded>

      <div>
        <HomepageBanners v-if="!isCoBranded" />

        <client-only>
          <template v-if="shouldDisplayCheckoutRestrictedByUserLocationPopup">
            <div class="new-container mx-auto px-20 mt-20">
              <UaeRestrictionModal
                :modal-view="shouldDisplayCheckoutRestrictedByUserLocationPopup"
                @closeUaeRestrictionModal="onCloseRestrictionModal"
              />
            </div>
          </template>
        </client-only>

        <div class="store-container">
          <b-container fluid class="px-20 px-sm-30">
            <div class="airgsm-container mx-auto">
              <div
                :class="[
                  'store-list pb-20 pb-sm-30 pb-md-40 pt-20',
                  listType === 'global' && packages_isHaveDVT ? 'pt-sm-20 pt-md-20' : 'pt-sm-30 pt-md-40',
                ]"
              >
                <h2
                  id="store-title"
                  data-testid="store-title"
                  :class="[
                    'typo-h1 mb-20',
                    {
                      'd-flex justify-content-center align-items-center gap-20':
                        listType === 'local-packages' || listType === 'regional-packages',
                      'text-center': listType !== 'local',
                    },
                    {
                      'mb-sm-20': packages_isHaveDVT,
                    },
                    {
                      'mb-sm-40': !packages_isHaveDVT,
                    },
                  ]"
                  v-if="listType !== 'global'"
                >
                  <template
                    v-if="(listType === 'local-packages' || listType === 'regional-packages') && getPageTitleImage"
                  >
                    <img
                      src="/assets/images/placeholders/homepage-sim-flag.png"
                      :data-src="getPageTitleImage"
                      :alt="getPageTitle"
                      :width="37"
                      :height="28"
                      class="shadowed-small"
                      v-lazy-load
                    />
                  </template>

                  {{ getPageTitle }}
                </h2>
                <div
                  class="store-countries-and-regions-list d-grid gap-10 gap-sm-30"
                  :class="{
                    'store-grid-cols-1 store-grid-cols-sm-2 store-grid-cols-md-3 store-grid-cols-lg-4':
                      listType === 'local' || (listType === 'local-packages' && list && list.length > 1 && !isSearch),
                    'store-grid-cols-1 store-grid-cols-sm-2':
                      listType === 'regional' || listType === 'regional-packages',
                    'grid-cols-1':
                      (listType === 'local-packages' && list && list.length === 1) ||
                      (listType === 'local-packages' && list && list.length > 1 && isSearch),
                  }"
                >
                  <client-only>
                    <template v-if="listIsLoading && (listType === 'local' || listType === 'regional')">
                      <template>
                        <skeleton-item
                          v-for="(item, index) in listSkeletonCount"
                          :key="`list-item-skeleton-${index}`"
                        />
                      </template>
                    </template>
                  </client-only>
                  <template
                    v-if="
                      !listIsLoading && list && list.length > 0 && (listType === 'local' || listType === 'regional')
                    "
                  >
                    <div
                      class="store-item aloo"
                      :id="getElementID(listType, item.id)"
                      v-for="(item, index) in list"
                      :key="`list-item-${index}`"
                    >
                      <store-item
                        :item="item"
                        :item-index="index"
                        :list-type="listType"
                        :show-data-voice-text="packages_isHaveDVT"
                        @onChangePlainType="onChangePlainTypeFromStoreItem"
                      />
                    </div>
                  </template>
                  <div
                    class="store-packages-list"
                    :class="{
                      'store-col-span-1 store-col-span-sm-2 store-col-span-md-3 store-col-span-lg-4':
                        listType === 'local-packages' && list && list.length > 1,
                      'store-col-span-1':
                        listType === 'regional-packages' ||
                        listType === 'global' ||
                        (listType === 'local-packages' && list && list.length === 1),
                      'store-col-span-1 store-col-span-sm-2': listType === 'regional-packages',
                      'd-none store-col-span-1 store-col-span-sm-2 store-col-span-md-3 store-col-span-lg-4':
                        listType === 'local',
                      'd-none store-col-span-1': listType === 'regional',
                      'regional-ssr-h0': regionalSsrHeightZero,
                    }"
                    ref="storePackagesList"
                  >
                    <template v-if="packages_isLoading && packages_skeletonCount > 0">
                      <client-only>
                        <div class="d-grid gap-30 store-grid-cols-1 store-grid-cols-sm-2 store-grid-cols-md-3">
                          <template>
                            <packages-skeleton-item
                              v-for="(item, index) in packages_skeletonCount"
                              :key="`list-item-skeleton-${index}`"
                            />
                          </template>
                        </div>
                      </client-only>
                    </template>
                    <template
                      v-if="listType === 'global' || listType === 'local-packages' || listType === 'regional-packages'"
                    >
                      <template v-if="!packages_isLoading && isReadyForPackages">
                        <template v-if="packages_isHaveDVT">
                          <DataTypeTabs
                            :selectedDataType="selectedDataPlanType"
                            type="horizontal"
                            @onChangePlainType="onChangePlainTypeFromStoreItem"
                            :class="[
                              'mt-0',
                              { 'mt-sm-15': listType === 'global' },
                              { 'mt-sm-5': listType !== 'global' },
                            ]"
                          />
                        </template>
                        <nuxt-child
                          :loading="packages_isLoading"
                          :list-fade="packages_startShowDVTChange"
                          :list="packages_list"
                          :list-type="listType"
                          :selected-country="selectedCountry"
                          :selected-data-type="selectedDataPlanType"
                          @onPackageDetailMounted="onPackageDetailMounted"
                        />
                      </template>
                    </template>
                  </div>
                </div>
                <template v-if="!listIsLoading && listType === 'local' && list && list.length > 0">
                  <div class="show-all-btn-wrapper">
                    <button
                      :class="['show-all-btn', { 'btn-cobranded': isCoBranded, 'btn-primary-hv': !isCoBranded }]"
                      :style="{
                        '--brand-colour': coBrandedBrandColour,
                        '--brand-text-colour': coBrandedTextColour,
                      }"
                      data-testid="show-all-countries-button"
                      @click="showCountries(!isAllCountriesShown)"
                      :disabled="isLoadingAllCountries"
                    >
                      <template v-if="isLoadingAllCountries">
                        <b-spinner small />
                      </template>
                      <template v-else>
                        <template v-if="!isAllCountriesShown">
                          <span>{{ $t('components.esims.view-all') }}</span>
                        </template>
                        <template v-else>
                          <span>{{ $t('components.esims.view-popular') }}</span>
                        </template>
                      </template>
                    </button>
                  </div>
                </template>
                <template v-if="!listIsLoading && listType === 'local-packages'">
                  <div class="show-all-btn-wrapper">
                    <nuxt-link
                      :class="[
                        'show-all-btn',
                        {
                          'btn-cobranded': isCoBranded && coBrandedBrandColour && coBrandedTextColour,
                          'btn-primary-hv': !isCoBranded,
                        },
                      ]"
                      :style="{
                        '--brand-colour': coBrandedBrandColour,
                        '--brand-text-colour': coBrandedTextColour,
                      }"
                      :to="localePath('/')"
                    >
                      <span>{{ $t('components.esims.view-all') }}</span>
                    </nuxt-link>
                  </div>
                </template>

                <template v-if="!listIsLoading && listType === 'regional-packages'">
                  <div class="show-all-btn-wrapper">
                    <nuxt-link class="show-all-btn btn-primary-hv" :to="localePath('/regional-esim')">
                      <span>{{ $t('components.esims.show-all-regions') }}</span>
                    </nuxt-link>
                  </div>
                </template>
              </div>
            </div>
          </b-container>
        </div>

        <client-only>
          <template v-if="signupRewardModal.showComp">
            <component
              :is="signupRewardModal.showCompData.is"
              v-bind="signupRewardModal.showCompData.props"
              v-on="signupRewardModal.showCompData.on"
            >
              <template v-slot:footer>
                <button
                  type="button"
                  class="call-to-action dark mb-2"
                  @click.prevent="goToEsimDetails(esimRedeemedOnSignup.id)"
                >
                  {{ $t('components.esim.earn.view-esim-button') }}
                </button>
                <button type="button" class="call-to-action" @click.prevent="hideVoucherRewardModal">
                  {{ $t('global.close') }}
                </button>
              </template>
            </component>
          </template>

          <template v-if="fistLoginRewardModal.showComp">
            <component
              :is="fistLoginRewardModal.showCompData.is"
              v-bind="fistLoginRewardModal.showCompData.props"
              v-on="fistLoginRewardModal.showCompData.on"
            >
              <template v-slot:footer>
                <button class="call-to-action dark" @click.prevent="firstModalShown">
                  {{ $t('components.money.earn.airmoney-earned-button') }}
                </button>
              </template>
            </component>
          </template>
        </client-only>
      </div>

      <HowDoesAiraloWork :key="`HowDoesAiraloWork-${crKey}`" />

      <WhyUseAiralo v-if="!isCoBranded" :data="whyAiraloReviews" :key="`WhyUseAiralo-${crKey}`" />

      <client-only>
        <CustomerReviews v-if="!isCoBranded" :data="customerReviews" :key="`CustomerReviews-${crKey}`" />
      </client-only>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import SectionHeroBase from '~/components/homepage/section-hero-base.vue'
  import SectionHeroCobranded from '~/components/homepage/section-hero-cobranded.vue'
  import Hero from '~/components/homepage/hero.vue'
  import CobrandingHeroTitle from '~/components/homepage/cobranding-hero-title.vue'
  import StoreItem from '~/components/homepage/country-list-item'
  import CountrySearch from '~/components/homepage/country-search.vue'
  import StoreTabs from '~/components/tabs/store-switcher-tabs.vue'
  import SkeletonItem from '~/components/skeleton/country-list-item-skeleton'
  import PackagesSkeletonItem from '~/components/skeleton/country-detail-item-skeleton'
  import DataTypeTabs from '~/components/homepage/data-type-tabs.vue'
  import pkgMxn from '~/mixins/package.js'
  import UaeRestrictionModal from '@/components/modals/uae-restriction-modal.vue'

  export default {
    name: 'homepage',
    components: {
      UaeRestrictionModal,
      SectionHeroBase,
      SectionHeroCobranded,
      Hero,
      CobrandingHeroTitle,
      CountrySearch,
      StoreItem,
      StoreTabs,
      SkeletonItem,
      PackagesSkeletonItem,
      DataTypeTabs,
      HowDoesAiraloWork: () => import('~/components/how-does-airalo-work'),
      WhyUseAiralo: () => import('~/components/why-use-airalo'),
      CustomerReviews: () => import('~/components/customer-reviews'),
      HomepageBanners: () => import('~/components/homepage/banners.vue'),
    },

    mixins: [pkgMxn(false, undefined)],

    async asyncData({ route, $axios, store, i18n }) {
      let regionalList = []
      let regionalListByName = []

      try {
        const { data } = await $axios.get('v3/regions')

        regionalList = data
        regionalListByName = regionalList.map((v) => v.slug)
      } catch (error) {
        console.error(error)
      }

      let listType
      if (!route.params.list) {
        listType = 'local'
      } else {
        if (route.params.list === 'regional' || route.params.list === 'global') listType = route.params.list
        else if (regionalListByName.includes(route.params.list)) listType = 'regional-packages'
        else listType = 'local-packages'
      }

      await store.dispatch('reviews/fetch_customer_reviews', i18n.locale)

      return {
        listType,
        regionalList,
        regionalListByName,
      }
    },

    async fetch() {
      await this.pageData('fetch')
    },

    data() {
      return {
        tabs: [
          {
            text: 'scenes.store.local-tab',
            type: 'local',
            route: '/',
          },
          {
            text: 'scenes.store.regional-tab',
            type: 'regional',
            route: '/regional-esim',
          },
          {
            text: 'scenes.store.global-tab',
            type: 'global',
            route: '/global-esim',
          },
        ],

        endPoints: {
          regions: 'v3/regions',
          regionPackages: '/v4/regions',
          globalPackages: '/v4/regions/world',
          countries: 'v3/countries',
          countryPackages: '/v4/countries',
        },
        isCountryClicked: false,
        clickedCountryId: '',

        list: [],
        listIsLoading: false,
        listSkeletonCount: 0,

        packages: [],
        packages_list: [],
        packages_isHaveDVT: false,
        packages_startShowDVTChange: false,
        packages_isLoading: false,
        packages_skeletonCount: 0,
        selectedDataPlanType: 'others',

        isReadyForPackages: false,

        selectedCountry: null,

        isAllCountriesShown: false,
        isLoadingAllCountries: false,

        calculatedPlacement: 4,
        rowStart: 1,

        signupRewardModal: {
          showComp: false,
          showCompData: null,
          modalShown: true,
        },

        fistLoginRewardModal: {
          showComp: false,
          showCompData: null,
          modalShown: true,
        },

        windowWidth: 0,

        regionalSsrHeightZero: false,

        cancelToken: {
          requestName: null,
          request: null,
          source: null,
        },
      }
    },

    computed: {
      ...mapGetters({
        isCoBranded: 'coBranding/getIsCoBranded',
        branding: 'coBranding/getBranding',
      }),

      customerReviews() {
        return this.$store.getters['reviews/get_customer_reviews']
      },

      coBrandedHeroTitle() {
        if (!this.branding?.hero_copy_text) {
          return this.$t('scenes.homepage.head-title')
        }

        return this.branding?.hero_copy_text
      },

      coBrandedBrandColour() {
        return this.branding?.background_color || ''
      },

      coBrandedTextColour() {
        return this.branding?.text_color || ''
      },

      crKey() {
        return this.$store.getters.get_cr_key
      },

      isSearch() {
        return this.$store.getters.get_searched
      },

      whyAiraloReviews() {
        return this.$store.getters['reviews/get_why_airalo_reviews']
      },

      esimRedeemedOnSignup() {
        const esim = this.$store.getters.get_user_esims()[0]
        return esim && this.$store.getters.get_should_show_signup_reward
          ? {
              ...esim.packages[0],
              id: esim.id,
              operator: esim.operator,
              merchant: esim.merchant,
            }
          : null
      },

      getPageTitle() {
        if (this.listType === 'local') {
          if (this.isAllCountriesShown && this.list?.length > 1) return this.$t('scenes.store.all-countries-title')
          else return this.$t('scenes.store.popular-title')
        } else if (this.listType === 'local-packages' && this.selectedCountry) return this.selectedCountry.title
        else if (this.listType === 'regional') return this.$t('scenes.store.regions-title')
        else if (this.listType === 'regional-packages' && this.selectedCountry) return this.selectedCountry.title

        return null
      },

      getPageTitleImage() {
        return this.selectedCountry?.image?.url
      },

      generatedListTitle() {
        switch (this.listType) {
          case 'local':
            return ''
          case 'local-packages': {
            if (this.selectedCountry) {
              return this.selectedCountry.title
            } else {
              return ''
            }
          }
          case 'regional':
            return this.$t('scenes.store.regions-title')
          case 'regional-packages': {
            if (this.selectedCountry) {
              return `${this.$t('scenes.store.regions-title')} | ${this.selectedCountry.title}`
            } else {
              return ''
            }
          }
          case 'global':
            return this.$t('scenes.store.global-tab')
        }
      },

      generatedPackageTitle() {
        if (this.$route.params.package) {
          return `| ${this.$route.params.package}-${this.$i18n.locale}`
        }

        return ''
      },

      generatedSeoTitle() {
        return `${this.generatedListTitle} ${this.generatedPackageTitle}`
      },

      generatedPageMeta() {
        switch (this.listType) {
          case 'local':
            return {
              title: this.$t('seo.homepage.title'),
              description: `${this.$t(`seo.homepage.description`)} ${this.generatedPackageTitle}`,
              keywords: this.$t(`seo.homepage.keywords`),
            }
          case 'local-packages':
            return {
              title: this.$t('seo.local.title', { country: this.selectedCountry?.title }),
              description: `${this.$t('seo.local.description', { country: this.selectedCountry?.title })} ${
                this.generatedPackageTitle
              }`,
              keywords: this.$t('seo.local.keywords', { country: this.selectedCountry?.title }),
            }
          case 'regional':
            return {
              title: this.$t('seo.regional.title'),
              description: `${this.$t('seo.regional.description')} ${this.generatedPackageTitle}`,
              keywords: this.$t('seo.regional.keywords'),
            }
          case 'regional-packages':
            return {
              title: this.$t('seo.region.title', { region: this.selectedCountry?.title }),
              description: `${this.$t('seo.region.description', { region: this.selectedCountry?.title })} ${
                this.generatedPackageTitle
              }`,
              keywords: this.$t('seo.region.keywords', { region: this.selectedCountry?.title }),
            }
          case 'global':
            return {
              title: this.$t('seo.global.title'),
              description: `${this.$t('seo.global.description')} ${this.generatedPackageTitle}`,
              keywords: this.$t('seo.global.keywords'),
            }
        }
      },

      shouldDisplayCheckoutRestrictedByUserLocationPopup() {
        return (
          this.listType === 'local' &&
          this.$store.getters['behaviors/get_is_checkout_by_buyer_location_restricted'] &&
          !this.$store.getters['behaviors/get_restrict_checkout_by_buyer_location_warning_seen']
        )
      },
    },

    head() {
      const searchBoxURL =
        this.$i18n.locale === 'en'
          ? 'https://www.airalo.com?q={search_term_string}&cb=sitelinks_searchbox'
          : `https://www.airalo.com/${this.$i18n.locale}?q={search_term_string}&cb=sitelinks_searchbox`

      const organizationStructuredData = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: 'Airalo',
        legalName: 'AirGSM Pte. Ltd.',
        url: 'https://www.airalo.com',
        logo: 'https://www.airalo.com/logo.png',
        contactPoint: {
          '@type': 'ContactPoint',
          contactType: 'customer support',
          email: 'support@airalo.com',
        },
        address: {
          '@type': 'PostalAddress',
          addressCountry: 'SG',
          postalCode: '039594',
          streetAddress: '6 Raffles Boulevard #03-308',
        },
        foundingDate: '2019',
        founders: [
          {
            '@type': 'Person',
            name: 'Bahadir Ozdemir',
          },
          {
            '@type': 'Person',
            name: 'Abraham Burak',
          },
        ],
        sameAs: [
          'https://www.facebook.com/airalocom',
          'https://www.instagram.com/airalocom/',
          'https://www.linkedin.com/company/airalocom',
          'https://twitter.com/airalocom',
          'https://www.youtube.com/channel/UCPL_PzFyZLgMOWOLIpNlkMw',
          'https://vk.com/airalo',
        ],
      }

      const websiteStructuredData = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: 'https://www.airalo.com',
        potentialAction: {
          '@type': 'SearchAction',
          target: searchBoxURL,
          'query-input': 'required name=search_term_string',
        },
      }

      const softwareApplicationIOSStructuredData = {
        '@context': 'https://schema.org',
        '@type': 'SoftwareApplication',
        name: 'Airalo: eSIM Mobile Data Packs',
        operatingSystem: 'iOS',
        downloadUrl: 'https://apps.apple.com/app/airalo-esim-store/id1475911720',
        applicationCategory: 'TravelApplication',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '4.7',
          ratingCount: '4300',
        },
        offers: {
          '@type': 'Offer',
          price: '0',
          priceCurrency: 'USD',
        },
      }

      const softwareApplicationANDROIDStructuredData = {
        '@context': 'https://schema.org',
        '@type': 'SoftwareApplication',
        name: 'Airalo: eSIM Mobile Data Packs',
        operatingSystem: 'Android',
        downloadUrl: 'https://play.google.com/store/apps/details?id=com.mobillium.airalo',
        applicationCategory: 'TravelApplication',
        aggregateRating: {
          '@type': 'AggregateRating',
          ratingValue: '3.9',
          ratingCount: '3990',
        },
        offers: {
          '@type': 'Offer',
          price: '0',
          priceCurrency: 'USD',
        },
      }

      const metaInfos = this.generatedPageMeta

      return {
        title: metaInfos.title,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: metaInfos.description,
          },
          {
            hid: 'keywords',
            name: 'keywords',
            content: metaInfos.keywords,
          },
        ],

        script: [
          {
            innerHTML: JSON.stringify(organizationStructuredData),
            type: 'application/ld+json',
          },
          {
            innerHTML: JSON.stringify(websiteStructuredData),
            type: 'application/ld+json',
          },
          {
            innerHTML: JSON.stringify(softwareApplicationIOSStructuredData),
            type: 'application/ld+json',
          },
          {
            innerHTML: JSON.stringify(softwareApplicationANDROIDStructuredData),
            type: 'application/ld+json',
          },
        ],
        __dangerouslyDisableSanitizers: ['script'],
      }
    },

    methods: {
      getListType(toRoute) {
        const route = toRoute || this.$route
        const regionalListByName = this.regionalListByName

        if (!route.params.list) {
          return 'local'
        } else {
          if (route.params.list === 'regional' || route.params.list === 'global') return route.params.list
          else if (regionalListByName.includes(route.params.list)) return 'regional-packages'
          else return 'local-packages'
        }
      },

      onPackageDetailMounted(payload) {
        this.onChangePlainTypeFromStoreItem(payload)
      },

      performRouteChanges_local(fromListType) {
        this.listSkeletonCount = 18

        if (this.list?.length <= 1 || fromListType !== 'local-packages') {
          this.listIsLoading = true
        }

        if (fromListType === 'local-packages') {
          this.isCountryClicked = false
        }
      },

      performRouteChanges_regional(fromListType) {
        this.listSkeletonCount = 4

        if (fromListType !== 'regional-packages') {
          this.listIsLoading = true
        }

        if (fromListType === 'local-packages') {
          this.isCountryClicked = false
        }
      },

      async performRouteChanges_localPackages(fromListType, toListType, to) {
        let selectedIndex = 0

        if (!this.selectedCountry || this.selectedCountry.slug !== to.params.list) {
          this.selectedCountry = this.list.find((v, i) => {
            selectedIndex = i
            return v.slug === to.params.list
          })
        } else {
          selectedIndex = this.list.map((v) => v.slug).indexOf(to.params.list)
        }

        if (!this.selectedCountry && !this.isSearch) {
          const countryData = await this.getLocalPackages({
            country: to.params.list,
          })

          this.list = [countryData]
          this.selectedCountry = { ...countryData }
          selectedIndex = 0
        } else if (this.isSearch) {
          this.listSkeletonCount = 1
          this.listIsLoading = true
          this.packages_skeletonCount = 3

          if (process.client) {
            this.packages_isLoading = true
          }

          const countryData = await this.getLocalPackages({
            country: to.params.list,
          })

          this.list = [countryData]
          this.selectedCountry = { ...countryData }
          selectedIndex = 0
          this.listIsLoading = false
          this.listSkeletonCount = 0
        }

        this.changeRowStart(selectedIndex, toListType)

        this.packages_skeletonCount = this.selectedCountry?.package_count || this.selectedCountry?.packages?.length

        if (process.client) {
          this.packages_isLoading = true
        }

        if (fromListType === 'local') {
          this.isCountryClicked = true
        }
      },

      performRouteChanges_regionalPackages(fromListType, toListType, to) {
        let selectedIndex = 0
        this.list = this.regionalList

        if (!this.selectedCountry || (this.selectedCountry && this.selectedCountry.slug !== to.params.list)) {
          this.selectedCountry = this.list.find((v, i) => {
            selectedIndex = i
            return v.slug === to.params.list
          })

          if (!this.selectedCountry) {
            this.selectedCountry = this.list.find((v, i) => {
              selectedIndex = i
              return v.slug === to.params.list
            })
          }
        } else {
          selectedIndex = this.list.map((v) => v.slug).indexOf(to.params.list)
        }

        this.changeRowStart(selectedIndex, toListType)
        this.packages_skeletonCount = this.selectedCountry.package_count

        if (process.client) {
          this.packages_isLoading = true
        }

        if (fromListType === 'regional') {
          this.isCountryClicked = true
        }
      },

      performRouteChanges_global(toListType) {
        this.changeRowStart(-1, toListType)
        this.packages_skeletonCount = 5

        if (process.client) {
          this.packages_isLoading = true
        }
      },

      routeChange(_toListType, toParams) {
        this.pageData('routeChange', toParams)
      },

      async pageData(callerName, toParams) {
        const params = toParams || this.$route.params

        switch (this.listType) {
          case 'local':
            await this.processCountriesList_local()
            break
          case 'local-packages':
            this.isReadyForPackages = false
            await this.processCountriesList_localPackages(params)
            break
          case 'regional':
            await this.processCountriesList_regional()
            break
          case 'regional-packages':
            this.isReadyForPackages = false
            await this.processCountriesList_regionalPackages(params)
            break
          case 'global':
            this.isReadyForPackages = false
            await this.processCountriesList_global()
            break
          default:
            break
        }
      },

      async processCountriesList_local() {
        if (process.client && this.isAllCountriesShown && this.$refs.countrySearch?.searchSims?.length > 0) {
          this.isAllCountriesShown = false
        }

        this.list = await this.getLocalCountries(this.isAllCountriesShown)

        this.packages_isHaveDVT = false

        if (process.client) {
          this.listIsLoading = false

          if (this.$refs.countrySearch?.searchSims?.length > 0) {
            this.$refs.countrySearch.clear(true)
          }
        }
      },

      async processCountriesList_localPackages(params) {
        const countryData = await this.getLocalPackages({
          country: params.list,
        })

        if (countryData) {
          this.selectedCountry = countryData
          this.isReadyForPackages = true
        }

        this.packages_isHaveDVT = false
        this.packages = countryData?.packages || []

        if (this.packages?.length > 0) {
          const planType = this.checkPackagesTypes(this.packages)

          if (planType === 'multiple') {
            if (!this.packages_isHaveDVT) {
              this.setPackagesListData(this.selectedDataPlanType !== 'others', 'processCountriesList_localPackages')
            }
            this.packages_isHaveDVT = true
          } else {
            this.packages_isHaveDVT = false
            this.packages_list = this.packages
          }
        }

        if (this.list?.length === 0) {
          this.list = [countryData]
        }

        if (process.client) {
          this.packages_isLoading = false

          if (this.isSearch) {
            this.$store.commit('set_searched', false)
          }
        } else {
          this.isCountryClicked = true
        }
      },

      async processCountriesList_regional() {
        this.list = this.regionalList

        this.packages_isHaveDVT = false

        if (process.client) {
          setTimeout(() => {
            this.listIsLoading = false
          }, 100)
        }
      },

      async processCountriesList_regionalPackages(params) {
        let regionalData = null

        if (this.list?.length === 0) {
          this.list = this.regionalList
        }

        if (this.selectedCountry?.slug === params.list && this.selectedCountry.packages) {
          regionalData = this.selectedCountry
          this.isReadyForPackages = true
        } else {
          regionalData = await this.getRegionalPackages({
            region: await params.list,
          })

          if (!this.selectedCountry?.packages) {
            this.selectedCountry = regionalData
            this.isReadyForPackages = true
          }
        }

        this.packages_isHaveDVT = false
        this.packages = regionalData?.packages || []

        if (this.packages?.length > 0) {
          const planType = this.checkPackagesTypes(this.packages)

          if (planType === 'multiple') {
            if (!this.packages_isHaveDVT) {
              this.setPackagesListData(this.selectedDataPlanType !== 'others', 'processCountriesList_regionalPackages')
            }
            this.packages_isHaveDVT = true
          } else {
            this.packages_isHaveDVT = false
            this.packages_list = this.packages
          }
        }

        if (process.client) {
          this.packages_isLoading = false

          if (this.isSearch) {
            this.$store.commit('set_searched', false)
          }
        } else {
          this.isCountryClicked = true
        }
      },

      async processCountriesList_global() {
        let globalData = null

        this.list = []

        if (this.selectedCountry?.packages && this.selectedCountry.slug === 'global') {
          globalData = this.selectedCountry
          this.isReadyForPackages = true
        } else {
          globalData = await this.getGlobalPackages()
          this.selectedCountry = { ...globalData, slug: 'global' }
          this.isReadyForPackages = true
        }

        this.packages_isHaveDVT = false
        this.packages = globalData?.packages || []

        if (this.packages?.length > 0) {
          const planType = this.checkPackagesTypes(this.packages)

          if (planType === 'multiple') {
            if (!this.packages_isHaveDVT) {
              this.setPackagesListData(this.selectedDataPlanType !== 'others', 'processCountriesList_global')
            }
            this.packages_isHaveDVT = true
          } else {
            this.packages_isHaveDVT = false
            this.packages_list = this.packages
          }
        }

        this.list = []

        if (process.client) {
          this.packages_isLoading = false

          if (this.isSearch) {
            this.$store.commit('set_searched', false)
          }
        }
      },

      checkPackagesTypes(packages) {
        const planTypesOfPackages = packages.map((v) => v.operator.plan_type)

        return this.$utils.getPackagesPlainType(planTypesOfPackages)
      },

      setPackagesListData(showDVT, caller) {
        let listForPackageListing = []

        if (caller === 'button') {
          this.packages_startShowDVTChange = true
        }

        if (showDVT) {
          listForPackageListing = this.packages.filter((v) => v.operator.plan_type === 'data-voice-text')
        } else {
          listForPackageListing = this.packages.filter((v) => v.operator.plan_type !== 'data-voice-text')
        }

        if (caller === 'button') {
          setTimeout(() => {
            this.packages_list = listForPackageListing || []
            this.packages_startShowDVTChange = false
          }, 200)
        } else {
          this.packages_list = listForPackageListing || []
        }
      },

      async getLocalCountries(isShowAll) {
        try {
          const uri = `${this.endPoints.countries}${!isShowAll ? '?type=popular' : '?sort=asc'}`
          const requestName = !isShowAll ? 'local-popuplar' : 'local-all'
          const { data } = await this.fetchStoreData(uri, requestName)
          return data
        } catch (error) {
          return null
        }
      },

      async getLocalPackages(payload) {
        try {
          const requestName = `local-packages-${payload.country}`
          const url = `${this.endPoints.countryPackages}/${payload.country}`
          const { data } = await this.fetchStoreData(url, requestName)

          if (data) {
            //  MC CHECK START
            if (data.packages.filter((p) => p.promotions !== undefined).length === 0) {
              console.error('MULTI-CURRENCY: promotions array not present in returned packages.', { url, data })
            }
            //  MC CHECK END

            if (process.client || process.browser) {
              this.$utils.sendEvent('userSetData', {
                last_visited_country_name: data.title,
                last_visited_country_id: data.id,
                last_visited_country_slug: data.slug,
              })
            }
          }

          try {
            const isUserEligible = this.$store.getters.is_authenticated
              ? this.$store.state.auth.user?.is_freemium_claimable
              : true
            if (
              this.$featureFlag.isActive('freemium') &&
              this.$featureFlag.isActive('freemium_web_checkout') &&
              isUserEligible
            ) {
              const freemiumResponse = await this.$axios.get(`v4/freemium/countries/${payload.country}`)
              if (freemiumResponse.data) {
                data.packages.unshift(freemiumResponse.data)
              }
            }
          } catch (e) {
            if (e.response?.data?.code !== 'common.not-found') {
              this.$sentry.addBreadcrumb({
                type: 'error',
                category: 'Freemium',
                message: 'Use Referral Or Discount Code Error',
                data: { e },
              })
            }
          }

          return data
        } catch (error) {
          this.$nuxt.context.redirect(
            301,
            this.localePath({
              name: 'index',
              params: {
                list: undefined,
                package: undefined,
              },
            })
          )
        }
      },

      async getRegions() {
        try {
          const { data } = await this.fetchStoreData(this.endPoints.regions, 'regions')
          return data
        } catch (error) {
          return null
        }
      },

      async getRegionalPackages(payload) {
        try {
          const url = this.endPoints.regionPackages + `/${payload.region}`
          const { data } = await this.fetchStoreData(url, 'regional-packages')

          if (data) {
            //  MC CHECK START
            if (data.packages.filter((p) => p.promotions !== undefined).length === 0) {
              console.error('MULTI-CURRENCY: promotions array not present in returned packages.', { url, data })
            }
            //  MC CHECK END

            if (process.client || process.browser) {
              this.$utils.sendEvent('userSetData', {
                last_visited_region_name: data.title,
                last_visited_region_id: data.id,
                last_visited_region_slug: data.slug,
              })
            }
          }

          return data
        } catch (error) {
          this.$nuxt.context.redirect(301, this.localePath('/regional-esim'))
        }
      },

      async getGlobalPackages() {
        try {
          const { data } = await this.fetchStoreData(this.endPoints.globalPackages, 'global-packages')

          //  MC CHECK START
          if (data.packages.filter((p) => p.promotions !== undefined).length === 0) {
            console.error('MULTI-CURRENCY: promotions array not present in returned packages.', {
              url: this.endPoints.globalPackages,
              data,
            })
          }
          //  MC CHECK END

          return data
        } catch (error) {
          return null
        }
      },

      async fetchStoreData(uri, requestName) {
        if (this.cancelToken.request) {
          this.cancelPendingRequest()
        }
        this.cancelToken.requestName = requestName
        this.cancelToken.source = this.$axios.CancelToken.source()
        this.cancelToken.request = { cancel: this.cancelToken.source.cancel, type: 'pending' }

        try {
          const response = await this.$axios.get(uri, {
            cancelToken: this.cancelToken.source.token,
            cache: {
              maxAge: 0,
              ignoreCache: true,
            },
          })

          this.clearOldRequest()

          return response
        } catch (error) {
          if (process.env.APP_ENVIRONMENT !== 'production' && process.env.APP_ENVIRONMENT !== 'prod') {
            this.logResponseErrors(error)
          }

          return null
        }
      },

      cancelPendingRequest() {
        this.cancelToken.request.cancel()
        this.clearOldRequest()
      },

      logResponseErrors(error) {
        if (this.$axios.isCancel(error)) {
          console.error({ error })
        }
      },

      clearOldRequest() {
        this.cancelToken.requestName = null
        this.cancelToken.source = null
        this.cancelToken.request = null
      },

      getElementID(type, id) {
        if (type === 'local' || type === 'local-packages') return `local-${id}`
        if (type === 'regional' || type === 'regional-packages') return `regional-${id}`
        return 'local-id'
      },

      changeRowStart(activeIndex, listType, caller) {
        this.calculatePlacement(listType, caller)

        const calculatedIndex = Math.ceil((activeIndex + 1) / this.calculatedPlacement) + 1
        this.rowStart = isNaN(calculatedIndex) ? 1 : calculatedIndex
      },

      calculatePlacement(listType, caller) {
        if (!process.client) {
          this.calculatedPlacement = 1
          return
        }

        let list = this.list

        if (listType === 'regional-packages' && caller === 'created') {
          list = this.regionalList
        }

        if (listType === 'global' || list?.length <= 1 || window.innerWidth < 576) {
          this.calculatedPlacement = 1
          return
        } else if (['regional', 'regional-packages'].includes(listType)) {
          if (window.innerWidth >= 576) {
            this.calculatedPlacement = 2
            return
          }
        } else {
          if (window.innerWidth >= 1110) {
            this.calculatedPlacement = 4
            return
          } else if (window.innerWidth >= 1024) {
            this.calculatedPlacement = 3
            return
          } else if (window.innerWidth >= 576) {
            this.calculatedPlacement = 2
            return
          }
        }

        this.calculatedPlacement = 1
      },

      async showCountries(isShow) {
        this.isLoadingAllCountries = true

        if (this.listType === 'local-packages') {
          this.$router.push(this.localePath('/'))
        }

        this.isAllCountriesShown = isShow

        await this.pageData('showCountries')

        this.isLoadingAllCountries = false

        this.$scrollTo(`#store-title`, {
          offset: -110,
          duration: 500,
        })
      },

      goToEsimDetails(id) {
        this.permanentlyDisableSignupRewardModal()
        this.$router.push(this.localePath(`/my-esims/detail/${id}`))
      },

      onChangePlainTypeFromStoreItem(payload) {
        this.selectedDataPlanType = payload

        const isShowDVT = payload === 'data-voice-text'

        this.setPackagesListData(isShowDVT, 'button')
      },

      onResize() {
        if (window.innerWidth != this.windowWidth) {
          this.windowWidth = window.innerWidth

          let rowStartIndex = 0
          if (this.listType === 'global') rowStartIndex = -1
          else if (this.listType === 'regional-packages')
            rowStartIndex = this.regionalList.findIndex((v) => v.slug === this.$route.params.list)
          else if (this.listType === 'local-packages' && this.list?.length > 1)
            rowStartIndex = this.list.findIndex((v) => v.slug === this.$route.params.list)

          this.changeRowStart(rowStartIndex, this.listType)
        }
      },

      onCountrySearchClearAfter() {
        if (this.isAllCountriesShown) {
          this.isAllCountriesShown = false
        }
      },

      firstModalShown() {
        this.fistLoginRewardModal.modalShown = false
        this.fistLoginRewardModal.showCompData.props.value = false
      },

      hideVoucherRewardModal() {
        this.signupRewardModal.modalShown = false
        this.signupRewardModal.showCompData.props.value = false
      },

      signupRewardModalComponent(payload) {
        this.signupRewardModal.showComp = true

        this.signupRewardModal.showCompData = {
          is: () => import('~/components/reward-modal.vue'),
          props: {
            value: this.signupRewardModal.modalShown,
            backdrop: true,
            type: 'sim',
            pkg: this.esimRedeemedOnSignup,
          },
          on: {
            hidden: () => {
              this.permanentlyDisableSignupRewardModal()
            },
          },
        }
      },

      permanentlyDisableSignupRewardModal() {
        this.$store.commit('set_should_show_signup_reward', false)
      },

      checkFirstLoginReward(welcomeData) {
        if (welcomeData) {
          const referralReward = welcomeData.reward
          const referralAmount = welcomeData.amount

          this.fistLoginRewardModalComponent({
            props: {
              amount: referralAmount,
              reward: referralReward,
              type: welcomeData.voucher_type === 'airmoney' ? 'airmoney' : 'referral',
            },
          })

          localStorage.setItem('referral-reward-shown', 'true')
        }
      },

      fistLoginRewardModalComponent(payload) {
        this.fistLoginRewardModal.showComp = true

        this.fistLoginRewardModal.showCompData = {
          is: () => import('~/components/reward-modal.vue'),
          props: {
            value: this.fistLoginRewardModal.modalShown,
            ...payload.props,
          },
          on: {
            hidden: () => {},
          },
        }
      },

      async afterMountedNextTick() {
        if (!this.$device.isCrawler && (process.client || process.browser)) {
          if (this.$store.getters.get_should_show_signup_reward) {
            this.signupRewardModalComponent()
          }

          const referralRewardShown = localStorage.getItem('referral-reward-shown')
          const isFirstLogin = this.$store.getters.get_is_first_login || localStorage.getItem('is-first-login')

          if (this.$store.getters.is_authenticated && !referralRewardShown && isFirstLogin) {
            try {
              const welcomeData = await this.$store.dispatch('fetch_user_welcome')
              this.$store.commit('set_is_first_login', true)

              this.removeLocalStorageItemIfPresent('referral-code')
              this.removeLocalStorageItemIfPresent('is-first-login')

              this.checkFirstLoginReward(welcomeData)
            } catch (err) {
              console.error(err)
            }
          }
        }
      },

      removeLocalStorageItemIfPresent(item) {
        if (localStorage.getItem(item)) {
          localStorage.removeItem(item)
        }
      },

      onCloseRestrictionModal() {
        this.$store.dispatch('behaviors/post_warning_seen', 'restrict_checkout_by_buyer_location')
      },
    },

    async beforeRouteUpdate(to, from, next) {
      if (process.client) {
        const toListType = this.getListType(to)
        const fromListType = this.getListType(from)

        this.listType = toListType
        this.packages = []

        let isToRoutePackageDetail = false

        if (from.params?.list && to.params?.list && from.params?.list !== to.params?.list) {
          this.selectedDataPlanType = 'others'
        }

        switch (toListType) {
          case 'local':
            this.selectedDataPlanType = 'others'
            this.performRouteChanges_local(fromListType)
            break
          case 'regional':
            this.selectedDataPlanType = 'others'
            this.performRouteChanges_regional(fromListType)
            break
          case 'local-packages':
            if (to.params?.package) isToRoutePackageDetail = true
            if (from.params?.package && !to.params?.package && from.params?.list === to.params?.list)
              isToRoutePackageDetail = true
            if (!isToRoutePackageDetail) {
              await this.performRouteChanges_localPackages(fromListType, toListType, to)
            }
            break
          case 'regional-packages':
            if (to.params?.package) isToRoutePackageDetail = true
            if (from.params?.package && !to.params?.package && from.params?.list === to.params?.list)
              isToRoutePackageDetail = true
            if (!isToRoutePackageDetail) {
              this.performRouteChanges_regionalPackages(fromListType, toListType, to)
            }
            break
          case 'global':
            if (to.params?.package) isToRoutePackageDetail = true
            if (from.params?.package && !to.params?.package && from.params?.list === to.params?.list)
              isToRoutePackageDetail = true
            if (!isToRoutePackageDetail) {
              this.performRouteChanges_global(toListType)
            }
            break
          default:
            break
        }

        this.routeChange(toListType, to.params)
      }
      next()
    },

    async created() {
      let rowStartIndex = 0
      if (this.listType === 'global') rowStartIndex = -1
      else if (this.listType === 'regional-packages')
        rowStartIndex = this.regionalList.findIndex((v) => v.slug === this.$route.params.list)

      if (process.client) {
        this.windowWidth = window.innerWidth

        window.removeEventListener('resize', this.onResize)
        window.addEventListener('resize', this.onResize)
      } else {
        if (this.listType === 'regional-packages') {
          this.regionalSsrHeightZero = true
        }

        this.changeRowStart(rowStartIndex, this.listType)
      }
    },

    mounted() {
      if (process.client) {
        if (this.listType === 'regional-packages') {
          let rowStartIndex = this.regionalList.findIndex((v) => v.slug === this.$route.params.list)
          this.changeRowStart(rowStartIndex, this.listType, 'created')
          this.regionalSsrHeightZero = false
        }
      }

      if (this.$store.state.auth.loggedIn && this?.$auth?.user?.email) {
        const userInfo = {
          email: this.$auth.user.email,
          firstName: this.$auth.user.first_name,
          lastName: this.$auth.user.last_name,
          identity: this.$auth.user.id,
          code: this.$auth.user.ranking?.code,
        }

        this.$utils.sendEvent('userInformationEvent', { userInfo })
      }

      const discountCode = this.$extractQueryParameter(window.location.href, 'code')
      if (discountCode) {
        sessionStorage.setItem('discountCode', discountCode)
      }

      this.$nextTick(this.afterMountedNextTick)
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.onResize)
    },

    scrollToTop: false,
  }
</script>

<style scoped>
  .sub-content {
    width: 100%;
    background: white;
    text-align: center;
    border-bottom: 1px solid #ddd;
    padding-top: 20px;
  }

  .how-it-works__image {
    display: table;
    margin: auto;
    border-radius: 100%;
    overflow: hidden;
  }

  .home-sections {
    padding-bottom: 2rem;
    background: white;
  }

  .home-sections .wrapper {
    max-width: 1120px;
    margin: auto;
  }

  @media all and (max-width: 768px) {
    .home-sections {
      margin-bottom: -1.25rem;
    }
  }

  .home-section {
    padding: 2rem;
    background: white;
  }

  .faq-link {
    appearance: none;
    border: none;
    color: #007bff;
    outline: none;
    background: transparent;
    padding: 12px;
    border-radius: 8px;
    text-decoration: none;
    font-weight: bold;
    line-height: 22px;
    transition: background 0.4s;
  }

  .faq-link:hover {
    background-color: #fafafa;
  }

  .regional-ssr-h0 {
    height: 0;
    overflow: hidden;
  }
</style>
